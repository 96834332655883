<template>
  <v-container v-if="!loading" id="dashboard" fluid tag="section">
    <div class="banker-dashboard-grid">
      <div class="banker-dashboard-section-values">
        <h1 class="banker-dashboard-section-values-title">
          {{ `DXA + ${partner.name}` }}
        </h1>
        <p class="banker-dashboard-section-values-sub-title">
          {{ $t("banker_values_sub_title") }}
        </p>
        <div class="banker-dashboard-section-values-card-flex">
          <div class="banker-dashboard-section-values-card">
            <span>{{ $t("investment_quantify_title") }}</span>
            <h2>
              {{ partner.totalInvestments }}
            </h2>
          </div>
        </div>
        <div class="banker-dashboard-section-values-cards">
          <div>
            <h2 class="banker-dashboard-section-values-cards-title">
              {{ $t("investment_values") }}
            </h2>
            <CardFormattedCurrency
              :cardTitle="$t('amount')"
              :totalInvested="partner.totalInvested"
            />
          </div>
          <!-- <div>
            <h2 class="banker-dashboard-section-values-cards-title">
              {{ $t("allocations_confirmed") }}
            </h2>
            <CardFormattedCurrency
              :cardTitle="$t('amount')"
              :totalInvested="partner.totalRequestConfirmed"
            />
          </div> -->
          <div>
            <h2 class="banker-dashboard-section-values-cards-title">
              {{ $t("allocations_pending") }}
            </h2>
            <CardFormattedCurrency
              :cardTitle="$t('amount')"
              :totalInvested="partner.totalRequestPending"
            />
          </div>
        </div>
      </div>
      <div>
        <h1 class="banker-dashboard-title-section-links">
          {{ $t("invite_new_investors_and_bankers") }}
        </h1>
        <p class="banker-dashboard-sub-title-section-links">
          {{ $t("invite_new_investors_and_bankers_sub_title") }}
        </p>
        <div
          class="banker-dashboard-section-link"
          v-if="partner.alphaRegisterUrl"
        >
          <span>{{ $t("invite_investor") }}</span>
          <h2>{{ $t("register_investor_alpha") }}</h2>
          <p>{{ partner.alphaRegisterUrl }}</p>
          <div class="banker-dashboard-section-link-copy-link">
            <a @click="gs.copy_url_to_clipboard(partner.alphaRegisterUrl)">
              {{ $t("click_to_copy_link") }}
            </a>
            <font-awesome-icon
              icon="fa-solid fa-copy"
              width="16px"
              height="16px"
              color="var(--primary)"
              @click="gs.copy_url_to_clipboard(partner.alphaRegisterUrl)"
            />
          </div>
        </div>
        <div
          class="banker-dashboard-section-link"
          v-if="partner.bankerRegisterUrl"
        >
          <span>{{ $t("invite_banker") }}</span>
          <h2>{{ $t("register_banker") }}</h2>
          <p>{{ partner.bankerRegisterUrl }}</p>
          <div class="banker-dashboard-section-link-copy-link">
            <a @click="gs.copy_url_to_clipboard(partner.bankerRegisterUrl)">
              {{ $t("click_to_copy_link") }}
            </a>
            <font-awesome-icon
              icon="fa-solid fa-copy"
              width="16px"
              height="16px"
              color="var(--primary)"
              @click="gs.copy_url_to_clipboard(partner.bankerRegisterUrl)"
            />
          </div>
        </div>
      </div>
    </div>
    <v-container
      class="white mt-4 rounded"
      tag="section"
      fluid
      v-if="users.length > 0"
    >
      <v-row>
        <h4 class="h4">{{ $tc("user", 2) }}</h4>
        <v-spacer></v-spacer>
        <v-btn
          @click="download_allocations_report"
          rounded
          color="primary"
          class="mr-2"
          :loading="allocationsReportDownloading"
        >
          <v-icon class="mr-2">mdi-download-box</v-icon>
          <span style="font-size: 13px">{{
            $t("download_allocations_report")
          }}</span>
        </v-btn>
        <v-btn
          @click="download_investors_report"
          rounded
          color="primary"
          :loading="investorsReportDownloading"
        >
          <v-icon class="mr-2">mdi-download-box</v-icon>
          <span style="font-size: 13px">{{
            $t("download_investors_report")
          }}</span>
        </v-btn>
      </v-row>

      <InvestorsTable :Users="users.map((x) => x.user)" />

      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          style="margin-right: 40px"
          @click="$router.push(`/${partnerId}/investors`)"
          >{{ $t("see_more") }}</v-btn
        >
      </v-row>
    </v-container>
  </v-container>

  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import InvestorsTable from "./InvestorsTable";
import moment from "moment";
import CardFormattedCurrency from "@/components/dxa/Currency/CardFormattedCurrency";

export default {
  name: "PartnerDashboard",
  components: {
    InvestorsTable,
    CardFormattedCurrency,
  },
  data: () => ({
    value: "",
    apiService: new ApiService(),
    gs: new GeneralServices(),
    total_value: 0,
    partner: {},
    users: [],
    indices: [],
    legend: [],
    investments_data_table: [],
    loading: false,
    investorsReportDownloading: false,
    allocationsReportDownloading: false,
    investments_chart: {
      labels: [],
      datasets: [],
    },
    totalInvested: [],
    totalRequestConfirmed: [],
    totalRequestPending: [],
    partnerId: null,
  }),

  async created() {
    this.loading = true;
    this.partnerId = JSON.parse(localStorage.getItem("user")).id;

    await this.getPartnerInvestmentValues();
    await this.getPartnerUsers();
  },
  computed: {
    options() {
      return {
        // legend: {
        //   display: false,
        // },
        elements: {
          line: {
            tension: 0,
          },
        },
        responsive: false,
        maintainAspectRatios: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value, index, values) => {
                  return `R$ ${value
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
                },
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return parseFloat(tooltipItems.yLabel)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
          },
        },
      };
    },
  },
  methods: {
    download_allocations_report() {
      this.allocationsReportDownloading = true;
      this.apiService
        .getRequest("partner/generate-partner-allocation-report")
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute(
            "download",
            `Relatório de alocações dos clientes.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.allocationsReportDownloading = false;
        })
        .catch((error) => {
          this.allocationsReportDownloading = false;
        });
    },
    download_investors_report() {
      this.investorsReportDownloading = true;
      this.apiService
        .getRequest("partner/generate-partner-investor-report")
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute(
            "download",
            `Relatório das carteiras administradas dos clientes.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.investorsReportDownloading = false;
        })
        .catch((error) => {
          this.investorsReportDownloading = false;
        });
    },
    async getPartnerInvestmentValues() {
      this.loading = true;
      await this.apiService
        .getRequest("partner/home")
        .then(({ content }) => {
          this.partner = content;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async getPartnerUsers() {
      let limitUsers = 5;

      await this.apiService
        .getRequest(`partner/inviteds?limit=${limitUsers}`)
        .then(({ content }) => {
          this.users = content;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
  },
};
</script>

<style scoped>
.banker-dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
}

.banker-dashboard-title-section-links {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
}

.banker-dashboard-sub-title-section-links {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 20px;
}

.banker-dashboard-section-link {
  background: var(--white);
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  padding: 20px;
  max-width: 360px;
}

.banker-dashboard-section-link > span {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  display: block;
  margin-bottom: 8px;
}

.banker-dashboard-section-link > h2 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: var(--dark);
  text-transform: capitalize;
}

.banker-dashboard-section-link > p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  margin-bottom: 8px;
}

.banker-dashboard-section-link-copy-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.banker-dashboard-section-link-copy-link > a {
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.banker-dashboard-section-link-copy-link > svg {
  cursor: pointer;
}

div.banker-dashboard-section-link + div.banker-dashboard-section-link {
  margin-top: 20px;
}

.banker-dashboard-section-values-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
}

.banker-dashboard-section-values-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 10px;
}

.banker-dashboard-section-values-card {
  background: var(--white);
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  padding: 20px;
  width: 300px;
}

.banker-dashboard-section-values-card > span {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 8px;
}

.banker-dashboard-section-values-card > h2 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.banker-dashboard-section-values-card-flex {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.banker-dashboard-section-values-cards-title {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20px;
}

.banker-dashboard-section-values-cards {
  display: grid;
  gap: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 769px) {
  .banker-dashboard-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    border-bottom: none;
  }

  .banker-dashboard-title-section-links {
    border-top: 1px solid #e9e9e9;
    padding-top: 20px;
  }
}
</style>
